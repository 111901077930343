<template>
  <div class="aside_menu">
    <ul class="aside_nav">
      <li v-for="(item, mainkey) in menulists"
          v-bind:key="mainkey">
        <span v-if="activemenu == mainkey">
          <a href="javascript:;"
             class="activeStyle"
             v-on:click="menuShink(mainkey, item.length)"
             :class="activeLi">
            <img :src="getImgUrl(mainkey)"
                 v-bind:alt="mainkey" />
            <span>{{ mainkey }}</span>
          </a>
        </span>
        <span v-else>
          <!-- <a v-if="mainkey == 'Parametres'"
             href="javascript:;"
             class="activeStyle"
             v-on:click="menuShink(mainkey, item.length)"
             :class="activeLi">
            <img :src="getImgUrl(mainkey)"
                 v-bind:alt="mainkey" />
            <span>{{ mainkey }}</span>
          </a> -->
          <a href="javascript:;"
             v-on:click="menuShink(mainkey, item.length)"
             :class="activeLi">
            <img :src="getImgUrl(mainkey)"
                 v-bind:alt="mainkey" />
            <span>{{ mainkey }}</span>
          </a>
        </span>
        <div class="menu_sub"
             v-bind:class="showUl"
             v-if="mainkey == activemenu && item.length > 0">
          <ul class="sub_ul">
            <li v-for="(subitem, subkey) in item"
                v-bind:key="subkey">
              <a v-on:click="subMenuShink(subitem.menupath)"
                 href="javascript:;">
                {{ subitem.menuname }}
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>
<style>
@import "./thesidebar.css";
</style>
<script>
export default {
  name: "RwvSideBar",
  data: () => ({
    activeLi: "",
    showUl: "",
    menuShrink: "",
    activemenu: "",
    prevmenu: "",
    role: "",
    menulists: {
      Accueil: "",
      Marches: [
        // { menuname: "TABLEAU DE BORD", menupath: "programme/working" },
        { menuname: "PROGRAMMES", menupath: "programme" },
        { menuname: "MARCHES", menupath: "Marche" },
        { menuname: "ENTREPRISES", menupath: "sous_traitants" }
      ],
      Tresorerie: [
        { menuname: "TABLEAU DE BORD", menupath: "tresorerie" },
        { menuname: "DECOMPTES", menupath: "decompte" },
        { menuname: "REGLEMENTS DES DECOMPTES", menupath: "Decaissement" },
        { menuname: "PLAN DE DECAISSEMENTS", menupath: "plan_de_decaissements" },
        // { menuname: "OPERATIONS BANCAIRES", menupath: "operation/listeDesTransactions" },
        { menuname: "DETTES BANCAIRES", menupath: "leveeDeFonds" }
      ],
      Analyses: [
        { menuname: "TABLEAU DE BORD", menupath: "analyses/board" },
        { menuname: "INDICATEURS", menupath: "analyses/indicateurs" },
        { menuname: "RESSOURCES", menupath: "analyses/recettes" },
        { menuname: "CHARGES", menupath: "analyses/charges" },
        { menuname: "COMPTABILITE", menupath: "analyses/comptabilite/" },
        { menuname: "CONTROLE DE GESTION", menupath: "analyses/controle_de_gestion" },
        { menuname: "BUSINESS PLANS", menupath: "analyses/addBplan" },
        { menuname: "RAPPORTS", menupath: "analyses/rapport" }
      ],
      // Parametres: [
      //   //{ menuname: "RUBRIQUES", menupath: "rubriques" },
      //   // { menuname: "UTILISATEURS", menupath: "utilisateurs" },
      //   // { menuname: "AUDIT", menupath: "Audits" }
      // ]
    }
  }),
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.role = userdetails.user_role.split("_")
    console.log(this.menulists[0])
    if (this.role[1] != "FER") {
      this.menulists = {
        Accueil: "",
        Marches: [
          // { menuname: "TABLEAU DE BORD", menupath: "programme/working" },
          { menuname: "PROGRAMMES", menupath: "programme" },
          { menuname: "LETTRES DE COMMANDE", menupath: "Marche" },
          { menuname: "ENTREPRISES", menupath: "sous_traitants" }
        ],
        Tresorerie: [
          // { menuname: "TABLEAU DE BORD", menupath: "programme/working" },
          { menuname: "FACTURES", menupath: "decompte" }
        ]
      }
      if (this.role[1] == "AGEROUTE") {
        this.menulists.Tresorerie[0].menuname="DECOMPTES"
        this.menulists.Marches[1].menuname="MARCHES"
      }
      
    } else {
      this.menulists.Tresorerie[0].menupath = "decompte/fer/list"
      this.menulists.Marches.push({ menuname: "MAITRES D'OUVRAGE DELEGUES", menupath: "maitres_d_ouvrage" })
      this.menulists.Marches.push({ menuname: "MISSIONS DE CONTRÔLE", menupath: "missions_controle" })
      if (this.role[0]=="ADMIN") {
        this.menulists={
          Accueil: "",
          Marches: [
            { menuname: "TABLEAU DE BORD", menupath: "programme/programme_dashboard" },
            // { menuname: "TABLEAU DE BORD", menupath: "programme/working" },
            { menuname: "PROGRAMMES", menupath: "programme" },
            { menuname: "MARCHES", menupath: "Marche" },
            { menuname: "MAITRES D'OUVRAGE DELEGUES", menupath: "maitres_d_ouvrage" },
            { menuname: "MISSIONS DE CONTRÔLE", menupath: "missions_controle" },
            { menuname: "ENTREPRISES", menupath: "sous_traitants" }
          ],
          Tresorerie: [
            { menuname: "TABLEAU DE BORD", menupath: "tresorerie" },
            { menuname: "DECOMPTES", menupath: "decompte/fer/list" },
            { menuname: "REGLEMENTS DES DECOMPTES", menupath: "Decaissement" },
            { menuname: "PLAN DE DECAISSEMENTS", menupath: "plan_de_decaissements" },
            // { menuname: "OPERATIONS BANCAIRES", menupath: "operation/listeDesTransactions" },
            { menuname: "DETTES BANCAIRES", menupath: "leveeDeFonds" },
            { menuname: "SUIVI DES EMPRUNTS", menupath: "leveeDeFonds/suividesetatsdesemprunts" },
            { menuname: "PROJECTIONS FINANCIERES", menupath: "tresorerie/prevision_financiere" },
          ],
          Analyses: [
            { menuname: "TABLEAU DE BORD", menupath: "analyses/board" },
            { menuname: "INDICATEURS", menupath: "analyses/indicateurs" },
            { menuname: "RESSOURCES", menupath: "analyses/recettes" },
            { menuname: "CHARGES", menupath: "analyses/charges" },
            { menuname: "COMPTABILITE", menupath: "analyses/comptabilite/" },
            { menuname: "CONTROLE DE GESTION", menupath: "analyses/controle_de_gestion" },
            { menuname: "BUSINESS PLANS", menupath: "analyses/addBplan" },
            // { menuname: "BUSINESS PLANS", menupath: "analyses/bplan" },
            { menuname: "RAPPORTS", menupath: "analyses/rapport" }
          ],
          Parametres: [
            // { menuname: "BANQUES", menupath: "banques" },
            { menuname: "TYPE PROGRAMMES", menupath: "types_programme" },
            { menuname: "RUBRIQUES", menupath: "rubriques" },
            { menuname: "UTILISATEURS", menupath: "utilisateurs" },
            { menuname: "AUDIT", menupath: "Audits" },
            { menuname: "PLAN COMPTABLE", menupath: "plan_comptable" }
          ]
        }
      }
      if (this.role[0]=="DT") {
        this.menulists={
          Accueil: "",
          Marches: [
            { menuname: "TABLEAU DE BORD", menupath: "programme/programme_dashboard"},
            { menuname: "PROGRAMMES", menupath: "programme" },
            { menuname: "MARCHES", menupath: "Marche" },
            { menuname: "MAITRES D'OUVRAGE DELEGUES", menupath: "maitres_d_ouvrage" },
            { menuname: "MISSIONS DE CONTRÔLE", menupath: "missions_controle" },
            { menuname: "ENTREPRISES", menupath: "sous_traitants" }
          ],
          Tresorerie: [
            // { menuname: "TABLEAU DE BORD", menupath: "tresorerie" },
            { menuname: "DECOMPTES", menupath: "decompte/fer/list" },
            { menuname: "REGLEMENTS DES DECOMPTES", menupath: "Decaissement" },
            { menuname: "PLAN DE DECAISSEMENTS", menupath: "plan_de_decaissements" },
            // { menuname: "OPERATIONS BANCAIRES", menupath: "operation/listeDesTransactions" },
            { menuname: "DETTES BANCAIRES", menupath: "leveeDeFonds" },
            { menuname: "SUIVI DES EMPRUNTS", menupath: "leveeDeFonds/suividesetatsdesemprunts" }
          ],
          Analyses: [
            { menuname: "TABLEAU DE BORD", menupath: "analyses/board" },
            { menuname: "INDICATEURS", menupath: "analyses/indicateurs" },
            { menuname: "RESSOURCES", menupath: "analyses/recettes" },
            { menuname: "CHARGES", menupath: "analyses/charges" },
            { menuname: "COMPTABILITE", menupath: "analyses/comptabilite/" },
            { menuname: "CONTROLE DE GESTION", menupath: "analyses/controle_de_gestion" },
            { menuname: "BUSINESS PLANS", menupath: "analyses/addBplan" },
            // { menuname: "BUSINESS PLANS", menupath: "analyses/bplan" },
            { menuname: "RAPPORTS", menupath: "analyses/rapport" }
          ],
          Parametres: [
            { menuname: "TYPE PROGRAMMES", menupath: "types_programme" },
            { menuname: "RUBRIQUES", menupath: "rubriques" },
            { menuname: "UTILISATEURS", menupath: "utilisateurs" },
            { menuname: "AUDIT", menupath: "Audits" }
          ]
        }
      }
      if (this.role[0]=="DAF" || this.role[0]=="DCG") {
        this.menulists={
          Accueil: "",
          Marches: [
            // { menuname: "TABLEAU DE BORD", menupath: "programme/working" },
            { menuname: "PROGRAMMES", menupath: "programme" },
            { menuname: "MARCHES", menupath: "Marche" },
            { menuname: "MAITRES D'OUVRAGE DELEGUES", menupath: "maitres_d_ouvrage" },
            { menuname: "MISSIONS DE CONTRÔLE", menupath: "missions_controle" },
            { menuname: "ENTREPRISES", menupath: "sous_traitants" }
          ],
          Tresorerie: [
            { menuname: "TABLEAU DE BORD", menupath: "tresorerie" },
            { menuname: "DECOMPTES", menupath: "decompte/fer/list" },
            { menuname: "REGLEMENTS DES DECOMPTES", menupath: "Decaissement" },
            { menuname: "PLAN DE DECAISSEMENTS", menupath: "plan_de_decaissements" },
            // { menuname: "OPERATIONS BANCAIRES", menupath: "operation/listeDesTransactions" },
            { menuname: "DETTES BANCAIRES", menupath: "leveeDeFonds" },
            { menuname: "SUIVI DES EMPRUNTS", menupath: "leveeDeFonds/suividesetatsdesemprunts" },
            { menuname: "PROJECTIONS FINANCIERES", menupath: "tresorerie/prevision_financiere" },
          ],
          Analyses: [
            { menuname: "TABLEAU DE BORD", menupath: "analyses/board" },
            { menuname: "INDICATEURS", menupath: "analyses/indicateurs" },
            { menuname: "RESSOURCES", menupath: "analyses/recettes" },
            { menuname: "CHARGES", menupath: "analyses/charges" },
            { menuname: "COMPTABILITE", menupath: "analyses/comptabilite/" },
            { menuname: "CONTROLE DE GESTION", menupath: "analyses/controle_de_gestion" },
            { menuname: "BUSINESS PLANS", menupath: "analyses/addBplan" },
            // { menuname: "BUSINESS PLANS", menupath: "analyses/bplan" },
            { menuname: "RAPPORTS", menupath: "analyses/rapport" }
          ],
          Parametres: [
            // { menuname: "BANQUES", menupath: "banques" },
            { menuname: "UTILISATEURS", menupath: "utilisateurs" },
            { menuname: "AUDIT", menupath: "Audits" },
            { menuname: "PLAN COMPTABLE", menupath: "plan_comptable" }
          ]
        }
      }
    }
  },
  methods: {
    menuShink: function(ev, itemLen) {
      this.activemenu = ev
      if (itemLen > 0) {
        if (this.prevmenu !== ev) {
          this.activeLi = "active_li"
          this.showUl = "show_ul"
          this.menuShrink = "menu_shrink"
        } else {
          if (this.activeLi == "") {
            this.activeLi = "active_li"
            this.showUl = "show_ul"
            this.menuShrink = "menu_shrink"
          } else {
            this.activeLi = ""
            this.showUl = ""
            this.menuShrink = ""
          }
        }
      } else {
        this.activeLi = ""
        this.showUl = ""
        this.menuShrink = ""
        this.$router.push({ path: "/dashboard" })
      }
      this.$emit("shrink", this.menuShrink)
      this.prevmenu = ev
    },
    subMenuShink(param) {
      this.activemenu = ""
      this.activeLi = ""
      this.showUl = ""
      this.menuShrink = ""
      this.$emit("shrink", this.menuShrink)
      this.$router.push({ path: "/" + param })
    },
    getImgUrl(img) {
      var images = require.context("../assets/img/", false, /\.svg$/)
      return images("./" + img + ".svg")
    }
  }
}
</script>
