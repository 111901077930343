import Vue from "vue"
import Router from "vue-router"

Vue.use(Router)

function guardMyroute(to, from, next) {
    if (localStorage.getItem("user") && localStorage.getItem("user") != null) {
        next()
    } else {
        window.location.href = "/"
        return
    }
}

function restricatedmyRoute(to, from, next) {
    if (localStorage.getItem("user") && localStorage.getItem("user") != null) {
        window.location.href = "/dashboard"
        return
    } else {
        next()
    }
}

function accessFER(to, from, next) {
    
    const userdetails = JSON.parse(localStorage.getItem("user"))
    let group = userdetails.user_role.split("_")
    if (group[1] == "FER") {
        next()
    } else {
        window.location.href = "/dashboard"
        return
    }
    
}

function accessAGEROUTE(to, from, next) {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    let group = userdetails.user_role.split("_")
    if (group[1] == "AGEROUTE" || group[1] == "DGIR") {
        next()
    } else {
        window.location.href = "/dashboard"
        return
    }
}

export default new Router({
    mode: "history",
    routes: [{
            path: "/",
            beforeEnter: restricatedmyRoute,
            component: () =>
                import ("@/components/Login/Index"),
            children: [{
                    path: "",
                    name: "Login",
                    component: () =>
                        import ("@/components/Login/Login")
                },
                {
                    path: "forgotPassword",
                    name: "ForgotPassword",
                    component: () =>
                        import ("@/components/Login/ForgotPassword")
                },
                {
                    path: "checkauth",
                    name: "login-loginAuthentication",
                    component: () =>
                        import ("@/components/Login/Authentication")
                },
                {
                    path: "resetPassword/:token/:id",
                    name: "ResetPassword",
                    component: () =>
                        import ("@/components/Login/ResetPassword")
                }
            ]
        },
        {
            path: "/dashboard",
            beforeEnter: guardMyroute,
            name: "Dashboard",
            component: () =>
                import ("@/components/Dashboard/Index")
        },
        {
            path: "/programme",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Programme/Index"),
            children: [{
                    path: "",
                    // beforeEnter: accessFER,
                    name: "Programme",
                    component: () =>
                        import ("@/components/Programme/Programme")
                },
                {
                    path: "createProgramme",
                    beforeEnter: accessFER,
                    name: "CreateProgramme",
                    component: () =>
                        import ("@/components/Programme/CreateProgramme")
                },
                {
                    path: "detailProgramme/:id",
                    // beforeEnter: accessFER,
                    name: "DetailProgramme",
                    component: () =>
                        import ("@/components/Programme/DetailProgramme")
                },
                {
                    path: "modifyProgramme/:id",
                    beforeEnter: accessFER,
                    name: "ModifyProgramme",
                    component: () =>
                        import ("@/components/Programme/ModifyProgramme")
                },
                {
                    path: "programme_dashboard",
                    beforeEnter: accessFER,
                    name: "ProgrammeBoard",
                    component: () =>
                        import ("@/components/Programme/ProgrammeBoard")
                },
                {
                  path: ":id/activite_dashboard",
                  beforeEnter: accessFER,
                  name: "ActiviteBoard",
                  component: () =>
                      import ("@/components/Programme/ActiviteBoard")
                },
                {
                  path: "activite/:id/marche_dashboard",
                  beforeEnter: accessFER,
                  name: "MarcheBoard",
                  component: () =>
                      import ("@/components/Programme/MarcheBoard")
                },
                {
                  path: "entreprise/:id/marche_dashboard",
                  beforeEnter: accessFER,
                  name: "MarcheEntrepriseBoard",
                  component: () =>
                      import ("@/components/Programme/MarcheEntrepriseBoard")
                },
                {
                  path: "marche/:id/decompte_dashboard",
                  beforeEnter: accessFER,
                  name: "DecompteBoard",
                  component: () =>
                      import ("@/components/Programme/DecompteBoard")
                },
            ]
        },
        //----------------------------------------UTILISATEUR------------------------------------------------
        {
            path: "/utilisateurs",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Utilisateurs/index"),
            children: [{
                    path: "",
                    // beforeEnter: accessFER,
                    name: "Utilisateur",
                    component: () =>
                        import ("@/components/Utilisateurs/Utilisateurs")
                },
                {
                    path: "ajouter",
                    // beforeEnter: accessFER,
                    name: "AddUser",
                    component: () =>
                        import ("@/components/Utilisateurs/Add")
                },
                {
                    path: "modifier/:id",
                    // beforeEnter: accessFER,
                    name: "UpdateUser",
                    component: () =>
                        import ("@/components/Utilisateurs/Update")
                },
            ]
        },
        // -------------------------------------------- MARCHE --------------------------------------------------
        {
            path: "/Marche",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Marches/Index"),
            children: [{
                    path: "",
                    beforeEnter: guardMyroute,
                    name: "Marche",
                    component: () =>
                        import ("@/components/Marches/Marches")
                },
                {
                    path: "detail/:id",
                    beforeEnter: guardMyroute,
                    name: "Detail",
                    component: () =>
                        import ("@/components/Marches/Details")
                },
                {
                    path: "modification/:id",
                    beforeEnter: guardMyroute,
                    name: "Modification",
                    component: () =>
                        import ("@/components/Marches/Update")
                },
                {
                    path: "add",
                    beforeEnter: guardMyroute,
                    name: "Add",
                    component: () =>
                        import ("@/components/Marches/Add")
                },
                //------------------------------------------------- AVENANT ------------------------------------------------
                {
                    path: "marches/:id/avenants",
                    beforeEnter: guardMyroute,
                    component: () =>
                        import ("@/components/Avenants/Index"),
                    children: [{
                        path: "",
                        beforeEnter: accessFER,
                        name: "Avenants",
                        component: () =>
                            import ("@/components/Avenants/Avenants")
                    }, ]
                },
                //------------------------------------------ ACCORDS DE FINANCEMENT ----------------------------------------
                {
                    path: "marches/:id/accords_de_financement",
                    beforeEnter: guardMyroute,
                    component: () =>
                        import ("@/components/AccordsFinancement/Index"),
                    children: [{
                        path: "",
                        beforeEnter: accessFER,
                        name: "AccordsFinancement",
                        component: () =>
                            import ("@/components/AccordsFinancement/AccordsFinancement")
                    }, ]
                },
            ]
        },
        {
            path: "/Decaissement",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Decaissement/Index"),
            children: [{
                    path: "",
                    beforeEnter: accessFER,
                    name: "Decaissement",
                    component: () =>
                        import ("@/components/Decaissement/List")
                },
                {
                    path: "/ordre-virements",
                    beforeEnter: accessFER,
                    name: "Virement",
                    component: () =>
                        import ("@/components/Decaissement/ordreVirements")
                },
                {
                    path: "/detail-virements/:id",
                    beforeEnter: accessFER,
                    name: "DVirement",
                    component: () =>
                        import ("@/components/Decaissement/detailsOrdreVirement")
                }
            ]
        },
        {
            path: "/decompte",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Decompte/Index"),
            children: [{
                    path: "",
                    beforeEnter: accessAGEROUTE,
                    name: "decompte",
                    component: () =>
                        import ("@/components/Decompte/Decompte")
                },
                {
                    path: "fer/list/",
                    beforeEnter: accessFER,
                    name: "DecompteValider",
                    component: () =>
                        import ("@/components/Decompte/ListDecompteFer")
                },
                {
                    path: "fer/detail/:id", //Detail des decompte FER
                    beforeEnter: accessFER,
                    name: "DecDetail",
                    component: () =>
                        import ("@/components/Decompte/DetailDecompteFER")
                },
                {
                    path: "ageroute/travaux", //pour la creation des decompte travaux ageroute
                    beforeEnter: accessAGEROUTE,
                    name: "CreateTravaux",
                    component: () =>
                        import ("@/components/Decompte/CreateDecompteTravauxAgeroute")
                },
                {
                  path: "fer/travaux", //pour la creation des decompte travaux fer
                  beforeEnter: accessFER,
                  name: "CreateTravauxFER",
                  component: () =>
                      import ("@/components/Decompte/CreateDecompteTravauxFER")
                },
                {
                  path: "fer/travaux/modifier/:id", //pour la creation des decompte travaux fer
                  beforeEnter: accessFER,
                  name: "UpdateTravauxFER",
                  component: () =>
                      import ("@/components/Decompte/UpdateDecompteTravauxFER")
                },
                {
                    path: "dgir/travaux", //pour la creation des decompte travaux dgir
                    beforeEnter: accessAGEROUTE,
                    name: "CreateDecompte",
                    component: () =>
                        import ("@/components/Decompte/CreateDecompteTravauxDGIR")
                },
                {
                    path: "prestation", //pour la creation decompte intellectuelle ageroute et dgir
                    beforeEnter: accessAGEROUTE,
                    name: "CreateDecomptePrestation",
                    component: () =>
                        import ("@/components/Decompte/CreatePrestationIntellectuelle")
                },
                {
                    path: "ageroute/modification/:id", //modification decompte ageroute
                    beforeEnter: accessAGEROUTE,
                    name: "UpdateDecompte",
                    component: () =>
                        import ("@/components/Decompte/UpdateDecompteAgeroute")
                },
                {
                  path: "ageroute/modification/intellectuel/:id", //modification decompte ageroute
                  beforeEnter: accessAGEROUTE,
                  name: "UpdateDecomptePrestation",
                  component: () =>
                      import ("@/components/Decompte/UpdateDecomptePrestation")
                },
                {
                    path: ":p/ageroute/modification/:id", //modification decompte partiel ageroute
                    beforeEnter: accessAGEROUTE,
                    name: "UpdateDecompteP",
                    component: () =>
                        import ("@/components/Decompte/UpdateDecompteAgeroute")
                },
                {
                    path: "dgir/modification/:id", //Modification decompte dgir
                    beforeEnter: accessAGEROUTE,
                    name: "ModifyDecompte",
                    component: () =>
                        import ("@/components/Decompte/UpdateDecompteDgir")
                },
                {
                    path: ":p/dgir/modification/:id", //Modification decompte partiel dgir
                    beforeEnter: accessAGEROUTE,
                    name: "ModifyDecompteP",
                    component: () =>
                        import ("@/components/Decompte/UpdateDecompteDgir")
                },
                {
                    path: "ageroute/detail/:id", // Detail des decompte ageroute
                    beforeEnter: accessAGEROUTE,
                    name: "DetailsDecompte",
                    component: () =>
                        import ("@/components/Decompte/DetailDecompteAgeroute")
                },
                {
                    path: "dgir/detail/:id", // detail des decompte dgir
                    beforeEnter: accessAGEROUTE,
                    name: "DetailDecompte",
                    component: () =>
                        import ("@/components/Decompte/DetailDecompteDgir")
                }
            ]
        },
        //----------------------------------------------- RUBRIQUES ---------------------------------------------
        {
            path: "/rubriques",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Rubriques/Index"),
            children: [{
                    path: "",
                    beforeEnter: accessFER,
                    name: "rubriques",
                    component: () =>
                        import ("@/components/Rubriques/Rubriques")
                },
                {
                    path: "ajoutRubriques",
                    beforeEnter: accessFER,
                    name: "AjoutRubriques",
                    component: () =>
                        import ("@/components/Rubriques/AjoutRubriques")
                },
                {
                    path: "detailsRubriques",
                    beforeEnter: accessFER,
                    name: "DetailsRubriques",
                    component: () =>
                        import ("@/components/Rubriques/DetailsRubriques")
                }
            ]
        },
        //----------------------------------------------- CATEGORIES PROGRAMME ---------------------------------------------
        {
          path: "/types_programme",
          beforeEnter: guardMyroute,
          component: () =>
              import ("@/components/CategorieProgramme/Index"),
          children: [{
                  path: "",
                  beforeEnter: accessFER,
                  name: "categorieProgramme",
                  component: () =>
                      import ("@/components/CategorieProgramme/CategorieProgrammes")
              },
              
          ]
        },
        //--------------------------------------------- LEVEE DE FONDS ------------------------------------------
        {
            path: "/leveeDeFonds",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/LeveeDeFonds/Index"),
            children: [{
                    path: "",
                    beforeEnter: accessFER,
                    name: "LeveeDeFonds",
                    component: () =>
                        import ("@/components/LeveeDeFonds/LeveeDeFonds")
                },
                {
                    path: "ajouter_levee_de_fonds",
                    beforeEnter: accessFER,
                    name: "AddLevee",
                    component: () =>
                        import ("@/components/LeveeDeFonds/Add")
                },
                {
                    path: "detail_levee_de_fonds/:id",
                    beforeEnter: accessFER,
                    name: "DetailLevee",
                    component: () =>
                        import ("@/components/LeveeDeFonds/Details")
                },
                {
                  path: "suividesetatsdesemprunts",
                  beforeEnter: accessFER,
                  name: "SuiviEtatEmprunt",
                  component: () =>
                      import ("@/components/LeveeDeFonds/SuiviEtatEmprunts")
              }
            ]
        },
        //--------------------------------------------- TRESORERIE ------------------------------------------
        {
          path: "/tresorerie",
          beforeEnter: guardMyroute,
          component: () =>
              import ("@/components/Tresorerie/Index"),
          children: [{
                  path: "",
                  beforeEnter: accessFER,
                  name: "Tresorerie",
                  component: () =>
                      import ("@/components/Tresorerie/Tresorerie")
              },
              {
                path: "prevision_financiere",
                beforeEnter: accessFER,
                name: "PrevisionFinanciere",
                component: () =>
                    import ("@/components/Tresorerie/PrevisionFinanciere")
              },
          ]
        },
        //--------------------------------------------- PLAN DE DECAISSEMENTS ------------------------------------------
        {
          path: "/plan_de_decaissements",
          beforeEnter: guardMyroute,
          component: () =>
              import ("@/components/PlanDecaissement/Index"),
          children: [{
                  path: "",
                  beforeEnter: accessFER,
                  name: "PlanDecaissements",
                  component: () =>
                      import ("@/components/PlanDecaissement/PlanDecaissements")
              },
          ]
        },
        //--------------------------------------------- FILTRES ------------------------------------------
        {
          path: "/filtre/:module",
          beforeEnter: guardMyroute,
          component: () =>
              import ("@/components/shared/ResultatFiltre")
        },
        {
          path: "/filtre",
          beforeEnter: guardMyroute,
          component: () =>
              import ("@/components/shared/ResultatFiltre")
        },
        //---------------------------------------------- UTILISATEURS -------------------------------------------
        {
            path: "/utilisateurs",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Utilisateurs/Utilisateurs")
                // children: [{
                //     path: "",
                //     beforeEnter: accessFER,
                //     name: "LeveeDeFonds",
                //     component: () =>
                //       import ("@/components/LeveeDeFonds/LeveeDeFonds")
                //   },
                //   {
                //     path: "ajouter_levee_de_fonds",
                //     beforeEnter: accessFER,
                //     name: "AddLevee",
                //     component: () =>
                //       import ("@/components/LeveeDeFonds/Add")
                //   },
                //   {
                //     path: "detail_levee_de_fonds/:id",
                //     beforeEnter: accessFER,
                //     name: "DetailLevee",
                //     component: () =>
                //       import ("@/components/LeveeDeFonds/Details")
                //   },
                // ]
        },
        // ----------------------------------------------AUDITS---------------------------------------------------
        {
            path: "/audits",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Audit/Index"),
            children: [{
                    path: "",
                    beforeEnter: accessFER,
                    name: "Audits",
                    component: () =>
                        import ("@/components/Audit/Audit")
                },
                {
                    path: "details/:id",
                    beforeEnter: accessFER,
                    name: "DetailsAudit",
                    component: () =>
                        import ("@/components/Audit/Details")
                },
            ]
        },
        // -------------------------------------------- DONNEES HISTORIQUES --------------------------------------------
        {
            path: "/donneeshistoriques",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/DonneesHistoriques/Index"),
            children: [{
                path: "",
                beforeEnter: accessFER,
                name: "DonneesHistoriques",
                component: () =>
                    import ("@/components/DonneesHistoriques/DonneesHisto")
            }, ]
        },
        // -------------------------------------------- MAITRES D'OUVRAGE --------------------------------------------
        {
            path: "/maitres_d_ouvrage",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Prestataires/Index"),
            children: [{
                path: "",
                beforeEnter: accessFER,
                name: "Prestataires",
                component: () =>
                    import ("@/components/Prestataires/Prestataires")
            }, ]
        },
        // -------------------------------------------- SOUS-TRAITANTS --------------------------------------------
        {
            path: "/sous_traitants",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Sous_traitants/Index"),
            children: [{
                path: "",
                beforeEnter: guardMyroute,
                name: "Sous_traitants",
                component: () =>
                    import ("@/components/Sous_traitants/Sous_traitants")
            }, ]
        },
        // ------------------------------------------- MISSIONS DE CONTRÔLE --------------------------------------------
        {
            path: "/missions_controle",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Mission_controles/Index"),
            children: [{
                path: "",
                beforeEnter: accessFER,
                name: "Mission_controles",
                component: () =>
                    import ("@/components/Mission_controles/Mission_controles")
            }, ]
        },
        //----------------------------------------------- BANQUES ---------------------------------------------
        {
            path: "/banques",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Banques/Index"),
            children: [{
                    path: "",
                    beforeEnter: accessFER,
                    name: "banques",
                    component: () =>
                        import ("@/components/Banques/Banques")
                },
                {
                    path: "ajoutBanques",
                    beforeEnter: accessFER,
                    name: "AjoutBanques",
                    component: () =>
                        import ("@/components/Banques/AjoutBanques")
                },

            ]
        },
        //----------------------------------------------- OPERATIONS -------------------------------------------
        {
            path: "/operation",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Operations/Index"),
            children: [{
                    path: "operationsBancaires",
                    beforeEnter: accessFER,
                    name: "OperationsBancaires",
                    component: () =>
                        import ("@/components/Operations/operationsBancaires")
                },
                {
                    path: "importEtatsComptables",
                    beforeEnter: accessFER,
                    name: "ImportEtatsComptables",
                    component: () =>
                        import ("@/components/Operations/ImportEtatsComptables")
                },
                {
                    path: "listeDesTransactions",
                    beforeEnter: accessFER,
                    name: "ListeDesTransactions",
                    component: () =>
                        import ("@/components/Operations/ListeDesTransactions")
                },
                {
                    path: "ajoutDeTransaction",
                    beforeEnter: accessFER,
                    name: "AjoutDeTransaction",
                    component: () =>
                        import ("@/components/Operations/AddTransaction")
                },
                {
                    path: "transactions_par_compte/:id",
                    beforeEnter: accessFER,
                    name: "TransactionsByCompte",
                    component: () =>
                        import ("@/components/Operations/TransactionsByCompte")
                }
            ]
        },
        //------------------------------------------------- ANALYSE ---------------------------------------------
        {
            path: "/analyses",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/Analyses/Index"),
            children: [{
                    path: "board",
                    beforeEnter: accessFER,
                    name: "Analysebord",
                    component: () =>
                        import ("@/components/Analyses/Analysebord")
                },
                {
                    path: "jauge",
                    beforeEnter: accessFER,
                    name: "Jauge",
                    component: () =>
                        import ("@/components/shared/Speedometer")
                },
                {
                    path: "indicateurs",
                    beforeEnter: accessFER,
                    name: "Indicateurs",
                    component: () =>
                        import ("@/components/Analyses/Indicateurs")
                },
                {
                    path: "recettes",
                    beforeEnter: accessFER,
                    name: "Recettes",
                    component: () =>
                        import ("@/components/Analyses/AnalyseRecettes")
                },
                {
                    path: "comptabilite",
                    beforeEnter: accessFER,
                    name: "Comptabilite",
                    component: () =>
                        import ("@/components/Analyses/comptabilite/Comptabilite"),
                },
                {
                  path: "ajoutDeTransactionComptable",
                  beforeEnter: accessFER,
                  name: "AjoutDeTransactionComptable",
                  component: () =>
                      import ("@/components/Analyses/comptabilite/AddTransaction")
                },
                {
                    path: "exercice_comptable",
                    beforeEnter: accessFER,
                    name: "addComptable",
                    component: () =>
                        import ("@/components/Analyses/comptabilite/Add")
                },
                {
                    path: "/analyses/comptabilite/detail_comptable/:id",
                    beforeEnter: accessFER,
                    name: "DetailsComptable",
                    component: () =>
                        import ("@/components/Analyses/comptabilite/Details")
                },
                {
                    path: "controle_de_gestion",
                    beforeEnter: accessFER,
                    name: "ControleDeGestion",
                    component: () =>
                        import ("@/components/Analyses/ControleGestion/ControleDeGestion"),
                },
                {
                  path: "ajoutDeTransactionControleGestion",
                  beforeEnter: accessFER,
                  name: "AjoutDeTransactionControleGestion",
                  component: () =>
                      import ("@/components/Analyses/ControleGestion/AddTransaction")
                },
                {
                    path: "charges",
                    beforeEnter: accessFER,
                    name: "Charges",
                    component: () =>
                        import ("@/components/Analyses/Charges")
                },
                {
                    path: "rapport",
                    beforeEnter: accessFER,
                    name: "Rapport",
                    component: () =>
                        import ("@/components/Analyses/Rapport"),
                },
                {
                    path: "/analyses/rapport/details_decomptes",
                    beforeEnter: accessFER,
                    name: "RapportDetailsDecompte",
                    component: () =>
                        import ("@/components/Analyses/rapports/Decomptes")
                },
                {
                  path: "/analyses/rapport/details_decomptes",
                  beforeEnter: accessFER,
                  name: "RapportDetailsDecompte",
                  component: () =>
                      import ("@/components/Analyses/rapports/Decomptes")
                },
                {
                    path: "/analyses/rapport/details_levee_fond",
                    beforeEnter: accessFER,
                    name: "RapportDetailsLeveeDeFond",
                    component: () =>
                        import ("@/components/Analyses/rapports/LeveeFondImpr")
                },
                {
                    path: "/analyses/rapport/detail_peage",
                    beforeEnter: accessFER,
                    name: "RapportPeage",
                    component: () =>
                        import ("@/components/Analyses/rapports/PeageImprim")
                },
              
                {
                    path: "/analyses/rapport/detail_engagement_financier",
                    beforeEnter: accessFER,
                    name: "RapportEngagementFinancier",
                    component: () =>
                        import ("@/components/Analyses/rapports/EngagementFinancier")
                },
              
              
                {
                    path: "/analyses/rapport/redevance_peage",
                    beforeEnter: accessFER,
                    name: "RapportRedevancePeage",
                    component: () =>
                        import ("@/components/Analyses/rapports/RedevancesPeage")
                },
              
                {
                    path: "/analyses/rapport/detail_redevance_pesage",
                    beforeEnter: accessFER,
                    name: "RapportRedevancePesage",
                    component: () =>
                        import ("@/components/Analyses/rapports/RedevancePesage")
                },
              
              
                {
                    path: "/analyses/rapport/detail_redevance_patente_vignette",
                    beforeEnter: accessFER,
                    name: "RapportRedevancePatenteVignette",
                    component: () =>
                        import ("@/components/Analyses/rapports/RedevancePatenteVignette")
                },
                {
                  path: "/analyses/rapport/detail_recette_aire_stationnement",
                  beforeEnter: accessFER,
                  name: "RapportRecetteAireStationnement",
                  component: () =>
                      import ("@/components/Analyses/rapports/RecetteAireStationnement")
                },
                {
                    path: "/analyses/rapport/detail_synthese_charge",
                    beforeEnter: accessFER,
                    name: "RapportSyntheseCharge",
                    component: () =>
                        import ("@/components/Analyses/rapports/SyntheseCharge")
                },
              
                {
                    path: "/analyses/rapport/detail_trafic_peage",
                    beforeEnter: accessFER,
                    name: "RapportTrafic_Peage",
                    component: () =>
                        import ("@/components/Analyses/rapports/DetailTraficPeage")
                },
              
                {
                    path: "/analyses/rapport/detail_patente_vignette",
                    beforeEnter: accessFER,
                    name: "RapportPatente_Vignette",
                    component: () =>
                        import ("@/components/Analyses/rapports/DetailPatenteVignette")
                },
                {
                    path: "bplan",
                    beforeEnter: accessFER,
                    name: "BusinessPlan",
                    component: () =>
                        import ("@/components/Analyses/BusinessPlan")
                },
                {
                    path: "addBplan",
                    beforeEnter: accessFER,
                    name: "AddBplan",
                    component: () =>
                        import ("@/components/Analyses/businessPlan/AddBplan")
                },
                {
                  path: "business-plan/:id/Produits",
                  beforeEnter: accessFER,
                  name: "Produits",
                  component: () =>
                      import ("@/components/Analyses/businessPlan/Produits")
                },
                {
                  path: "business-plan/:id/resultat",
                  beforeEnter: accessFER,
                  name: "RecapBusiness",
                  component: () =>
                      import ("@/components/Analyses/businessPlan/RecapBusiness")
                },
                {
                  path: "business-plan/:projet/produits/:produit/charges_ressources",
                  beforeEnter: accessFER,
                  name: "ChargesRessources",
                  component: () =>
                      import ("@/components/Analyses/businessPlan/ChargesRessources")
                },
                {
                  path: "business-plan/:projet/produits/:produit/charges/:charge/details",
                  beforeEnter: accessFER,
                  name: "DetailCharges",
                  component: () =>
                      import ("@/components/Analyses/businessPlan/DetailCharges")
                },
                {
                  path: "business-plan/:projet/produits/:produit/ressources/:ressource/details",
                  beforeEnter: accessFER,
                  name: "DetailRessources",
                  component: () =>
                      import ("@/components/Analyses/businessPlan/DetailRessources")
                },
                {
                    path: "investissement",
                    beforeEnter: accessFER,
                    name: "Investissement",
                    component: () =>
                        import ("@/components/Analyses/businessPlan/Investissement")
                },
                {
                    path: "resultats",
                    beforeEnter: accessFER,
                    name: "Resultats",
                    component: () =>
                        import ("@/components/Analyses/businessPlan/Resultats")
                },
                {
                    path: "projection",
                    beforeEnter: accessFER,
                    name: "Projection",
                    component: () =>
                        import ("@/components/Analyses/businessPlan/ProjectionTarif")
                }
            ]
        },
        //----------------------------------------------- BANQUES ---------------------------------------------
        {
            path: "/plan_comptable",
            beforeEnter: guardMyroute,
            component: () =>
                import ("@/components/PlanComptes/Index"),
            children: [{
                path: "",
                beforeEnter: accessFER,
                name: "planComptes",
                component: () =>
                    import ("@/components/PlanComptes/PlanComptes")
            }, ]
        },
    ]
})